define("ember-keyboard/index", ["exports", "ember-keyboard/utils/get-mouse-code", "ember-keyboard/decorators/key-responder", "ember-keyboard/decorators/on-key", "ember-keyboard/listeners/key-events", "ember-keyboard/listeners/mouse-events", "ember-keyboard/listeners/touch-events", "ember-keyboard/utils/trigger-event"], function (_exports, _getMouseCode, _keyResponder, _onKey, _keyEvents, _mouseEvents, _touchEvents, _triggerEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "click", {
    enumerable: true,
    get: function get() {
      return _mouseEvents.click;
    }
  });
  _exports.getCode = getCode;
  _exports.getKeyCode = getKeyCode;
  Object.defineProperty(_exports, "getMouseCode", {
    enumerable: true,
    get: function get() {
      return _getMouseCode.default;
    }
  });
  Object.defineProperty(_exports, "keyDown", {
    enumerable: true,
    get: function get() {
      return _keyEvents.keyDown;
    }
  });
  Object.defineProperty(_exports, "keyPress", {
    enumerable: true,
    get: function get() {
      return _keyEvents.keyPress;
    }
  });
  Object.defineProperty(_exports, "keyResponder", {
    enumerable: true,
    get: function get() {
      return _keyResponder.default;
    }
  });
  Object.defineProperty(_exports, "keyUp", {
    enumerable: true,
    get: function get() {
      return _keyEvents.keyUp;
    }
  });
  Object.defineProperty(_exports, "mouseDown", {
    enumerable: true,
    get: function get() {
      return _mouseEvents.mouseDown;
    }
  });
  Object.defineProperty(_exports, "mouseUp", {
    enumerable: true,
    get: function get() {
      return _mouseEvents.mouseUp;
    }
  });
  Object.defineProperty(_exports, "onKey", {
    enumerable: true,
    get: function get() {
      return _onKey.default;
    }
  });
  Object.defineProperty(_exports, "touchEnd", {
    enumerable: true,
    get: function get() {
      return _touchEvents.touchEnd;
    }
  });
  Object.defineProperty(_exports, "touchStart", {
    enumerable: true,
    get: function get() {
      return _touchEvents.touchStart;
    }
  });
  Object.defineProperty(_exports, "triggerKeyDown", {
    enumerable: true,
    get: function get() {
      return _triggerEvent.triggerKeyDown;
    }
  });
  Object.defineProperty(_exports, "triggerKeyPress", {
    enumerable: true,
    get: function get() {
      return _triggerEvent.triggerKeyPress;
    }
  });
  Object.defineProperty(_exports, "triggerKeyUp", {
    enumerable: true,
    get: function get() {
      return _triggerEvent.triggerKeyUp;
    }
  });
  function getCode() {
    throw new Error('ember-keyboard: `getCode` has been removed. There is no longer a need for this function as you can directly specify `key` and/or `code` values');
  }
  function getKeyCode() {
    throw new Error('ember-keyboard: `getKeyCode` has been removed. There is no longer a need for this function as you can directly specify `key` and/or `code` values');
  }
});
define("ember-window-mock/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports._setCurrentHandler = void 0;
  var exportedWindow;
  var _setCurrentHandler;
  if (true /* DEBUG */) {
    // this Proxy handler will be used to preserve the unaltered behavior of the window global by default
    var doNothingHandler = {
      get: function get(target, prop) {
        var value = Reflect.get(target, prop);

        // make sure the function receives the original window as the this context! (e.g. alert will throw an invalid invocation error)
        if (typeof value === 'function') {
          return new Proxy(value, {
            apply: function apply(t, _thisArg, argumentsList) {
              return Reflect.apply(value, target, argumentsList);
            }
          });
        }
        return value;
      },
      set: Reflect.set,
      has: Reflect.has,
      deleteProperty: Reflect.deleteProperty,
      getOwnPropertyDescriptor: Reflect.getOwnPropertyDescriptor,
      defineProperty: Reflect.defineProperty
    };
    var currentHandler = doNothingHandler;

    // private function to replace the default handler in tests
    _exports._setCurrentHandler = _setCurrentHandler = function _setCurrentHandler() {
      var handler = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : doNothingHandler;
      return currentHandler = handler;
    };
    var proxyHandler = {
      get: function get() {
        var _currentHandler;
        return (_currentHandler = currentHandler).get.apply(_currentHandler, arguments);
      },
      set: function set() {
        var _currentHandler2;
        return (_currentHandler2 = currentHandler).set.apply(_currentHandler2, arguments);
      },
      has: function has() {
        var _currentHandler3;
        return (_currentHandler3 = currentHandler).has.apply(_currentHandler3, arguments);
      },
      deleteProperty: function deleteProperty() {
        var _currentHandler4;
        return (_currentHandler4 = currentHandler).deleteProperty.apply(_currentHandler4, arguments);
      },
      getOwnPropertyDescriptor: function getOwnPropertyDescriptor() {
        var _currentHandler5;
        return (_currentHandler5 = currentHandler).getOwnPropertyDescriptor.apply(_currentHandler5, arguments);
      },
      defineProperty: function defineProperty() {
        var _currentHandler6;
        return (_currentHandler6 = currentHandler).defineProperty.apply(_currentHandler6, arguments);
      }
    };
    exportedWindow = new Proxy(window, proxyHandler);
  } else {
    exportedWindow = window;
  }
  var _default = _exports.default = exportedWindow;
});
define("ember-mapbox-gl/templates/components/mapbox-gl", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Rv9HK10X",
    "block": "[[[41,[30,0,[\"_loader\",\"isLoaded\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"call\",\"control\",\"image\",\"layer\",\"marker\",\"on\",\"popup\",\"source\",\"instance\"],[[50,\"mapbox-gl-call\",0,null,[[\"obj\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-control\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-image\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-layer\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-marker\",0,null,[[\"map\",\"MapboxGl\"],[[30,0,[\"_loader\",\"map\"]],[30,0,[\"_loader\",\"MapboxGl\"]]]]],[50,\"mapbox-gl-on\",0,null,[[\"eventSource\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-popup\",0,null,[[\"map\",\"MapboxGl\"],[[30,0,[\"_loader\",\"map\"]],[30,0,[\"_loader\",\"MapboxGl\"]]]]],[50,\"mapbox-gl-source\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[30,0,[\"_loader\",\"map\"]]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"_loader\",\"error\"]],[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[30,0,[\"_loader\",\"error\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[54,[\"error rendering mapbox-gl\",[30,0,[\"_loader\",\"error\"]]]]],[1,\"\\n\"]],[]]]],[]],null]],[]]]],[\"&default\",\"&else\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"has-block\",\"log\"]]",
    "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl.hbs",
    "isStrictMode": false
  });
});
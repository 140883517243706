define("ember-mapbox-gl/templates/components/mapbox-gl-popup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ht5EPuYr",
    "block": "[[[40,[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"on\"],[[50,\"mapbox-gl-on\",0,null,[[\"eventSource\"],[[30,0,[\"popup\"]]]]]]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[30,0,[\"domContent\"]]],null]]],[\"&default\"],false,[\"in-element\",\"-in-el-null\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-popup.hbs",
    "isStrictMode": false
  });
});
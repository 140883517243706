define("liquid-fire/transitions/move-over", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = moveOver;
  function moveOver(dimension, direction, opts) {
    var _this = this;
    var oldParams = {},
      newParams = {},
      firstStep,
      property,
      measure;
    if (dimension.toLowerCase() === 'x') {
      property = 'translateX';
      measure = 'width';
    } else {
      property = 'translateY';
      measure = 'height';
    }
    if ((0, _liquidFire.isAnimating)(this.oldElement, 'moving-in')) {
      firstStep = (0, _liquidFire.finish)(this.oldElement, 'moving-in');
    } else {
      (0, _liquidFire.stop)(this.oldElement);
      firstStep = _liquidFire.Promise.resolve();
    }
    return firstStep.then(function () {
      var bigger = biggestSize(_this, measure);
      oldParams[property] = bigger * direction + 'px';
      newParams[property] = ['0px', -1 * bigger * direction + 'px'];
      return _liquidFire.Promise.all([(0, _liquidFire.animate)(_this.oldElement, oldParams, opts), (0, _liquidFire.animate)(_this.newElement, newParams, opts, 'moving-in')]);
    });
  }
  function biggestSize(context, dimension) {
    var sizes = [];
    if (context.newElement) {
      sizes.push(parseInt(context.newElement.style[dimension], 10));
      sizes.push(parseInt(context.newElement.parentElement.style[dimension], 10));
    }
    if (context.oldElement) {
      sizes.push(parseInt(context.oldElement.style[dimension], 10));
      sizes.push(parseInt(context.oldElement.parentElement.style[dimension], 10));
    }
    return Math.max.apply(null, sizes);
  }
});
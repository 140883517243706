define("ember-mapbox-gl/components/mapbox-gl-on", ["exports", "@ember/debug", "@ember/object", "@ember/utils", "@ember/runloop", "@ember/component"], function (_exports, _debug, _object, _utils, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Adds an action to listen for [mapbox events](https://www.mapbox.com/mapbox-gl-js/api/#map#on).
  
    ### Positional Parameters
    - `eventSource`
      - The first positional parameter. The event type to listen for.
    - `action`
      - The second positional parameter. The action provided by the consumer to call when the event is triggered.
  
    ### Example
    ```hbs
    {{#mapbox-gl as |map|}}
      {{map.on 'click' (action 'mapClicked')}}
    {{/mapbox-gl}}
    ```
  
    ```javascript
    import Controller from '@ember/controller';
  
    export default Controller.extend({
      actions: {
        mapClicked({ target: map, point }) {
          console.log(map, point);
        }
      }
    });
    ```
  
    @class MapboxGLOn
   */
  var MapboxGlOnComponent = _component.default.extend({
    tagName: '',
    eventSource: null,
    event: null,
    layerId: null,
    action: null,
    _prevEvent: null,
    _prevLayerId: null,
    _event: (0, _object.computed)('event', function () {
      var event = this.event;
      (true && !(typeof event === 'string') && (0, _debug.assert)("mapbox-gl-event requires event to be a string, was ".concat(event), typeof event === 'string'));
      return event;
    }).readOnly(),
    _layerId: (0, _object.computed)('layerId', '_action', function () {
      var layerId = this.layerId,
        _action = this._action;
      if (layerId === _action) {
        return null;
      }
      return layerId;
    }).readOnly(),
    _action: (0, _object.computed)('layerId', 'action', function () {
      var layerId = this.layerId,
        action = this.action;
      return action || layerId;
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      this._boundOnEvent = _runloop.run.bind(this, this._onEvent);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var eventSource = this.eventSource,
        _layerId = this._layerId,
        _event = this._event,
        _prevEvent = this._prevEvent,
        _prevLayerId = this._prevLayerId,
        _action = this._action;
      (true && !((0, _utils.isPresent)(eventSource)) && (0, _debug.assert)('mapbox-gl-event requires an eventSource', (0, _utils.isPresent)(eventSource)));
      (true && !((0, _utils.isPresent)(_action)) && (0, _debug.assert)('mapbox-gl-event requires an action', (0, _utils.isPresent)(_action)));
      if (_event !== _prevEvent || _layerId !== _prevLayerId) {
        if (_prevEvent) {
          if (_prevLayerId) {
            eventSource.off(_prevEvent, _prevLayerId, this._boundOnEvent);
          } else {
            eventSource.off(_prevEvent, this._boundOnEvent);
          }
        }
        this._prevEvent = _event;
        this._prevLayerId = _layerId;
        if (_layerId) {
          eventSource.on(_event, _layerId, this._boundOnEvent);
        } else {
          eventSource.on(_event, this._boundOnEvent);
        }
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      var eventSource = this.eventSource,
        _prevEvent = this._prevEvent,
        _prevLayerId = this._prevLayerId;
      if (eventSource && _prevEvent) {
        if (_prevLayerId) {
          eventSource.off(_prevEvent, _prevLayerId, this._boundOnEvent);
        } else {
          eventSource.off(_prevEvent, this._boundOnEvent);
        }
      }
    },
    _onEvent: function _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this._action.apply(this, arguments);
    }
  });
  MapboxGlOnComponent.reopenClass({
    positionalParams: ['event', 'layerId', 'action']
  });
  var _default = _exports.default = MapboxGlOnComponent;
});
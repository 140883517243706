define("ember-keyboard/utils/is-key", ["exports", "ember-keyboard/utils/keyboard-listener", "ember-keyboard/utils/platform", "ember-keyboard/fixtures/key-maps", "ember-keyboard/fixtures/modifiers-array", "ember-keyboard/utils/get-mouse-name"], function (_exports, _keyboardListener, _platform, _keyMaps, _modifiersArray, _getMouseName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isKey;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var ALL_SYMBOL = '_all';
  function isKey(listenerOrListenerName, event) {
    var platform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (0, _platform.default)();
    var listener;
    if (listenerOrListenerName instanceof _keyboardListener.default) {
      listener = listenerOrListenerName;
    } else if (typeof listenerOrListenerName === 'string') {
      listener = _keyboardListener.default.parse(listenerOrListenerName, platform);
    } else {
      throw new Error('Expected a `string` or `KeyCombo` as `keyComboOrKeyComboString` argument to `isKey`');
    }
    if (listener.type !== event.type) {
      return false;
    }
    if (isAll(listener)) {
      return true;
    }
    if (modifiersMatch(listener, event) && (keyOrCodeMatches(listener, event) || mouseButtonMatches(listener, event))) {
      return true;
    }
    return specialCaseMatches(listener, event, platform);
  }
  function isAll(listener) {
    return listener.keyOrCode === ALL_SYMBOL && listener.altKey === false && listener.ctrlKey === false && listener.metaKey === false && listener.shiftKey === false;
  }
  function modifiersMatch(listener, keyboardEvent) {
    return listener.type === keyboardEvent.type && listener.altKey === keyboardEvent.altKey && listener.ctrlKey === keyboardEvent.ctrlKey && listener.metaKey === keyboardEvent.metaKey && listener.shiftKey === keyboardEvent.shiftKey;
  }
  function keyOrCodeMatches(listener, keyboardEvent) {
    if (!(keyboardEvent instanceof KeyboardEvent)) {
      return false;
    }
    if (listener.keyOrCode === ALL_SYMBOL) {
      return true;
    }
    return listener.keyOrCode === keyboardEvent.code || listener.keyOrCode === keyboardEvent.key;
  }
  function mouseButtonMatches(listener, mouseEvent) {
    if (!(mouseEvent instanceof MouseEvent)) {
      return false;
    }
    if (listener.keyOrCode === ALL_SYMBOL) {
      return true;
    }
    return listener.keyOrCode === (0, _getMouseName.default)(mouseEvent.button);
  }
  function specialCaseMatches(keyboardListener, keyboardEvent, platform) {
    if (onlyModifiers([], keyboardListener) && onlyModifiers(['shift'], keyboardEvent)) {
      return keyboardEvent.key === keyboardListener.keyOrCode;
    }
    if (onlyModifiers(['shift'], keyboardListener) && onlyModifiers(['shift'], keyboardEvent)) {
      return rootKeyForShiftKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    if (platform === 'Macintosh' && onlyModifiers(['alt'], keyboardListener) && onlyModifiers(['alt'], keyboardEvent)) {
      return rootKeyForMacAltKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    if (platform === 'Macintosh' && onlyModifiers(['shift', 'alt'], keyboardListener) && onlyModifiers(['shift', 'alt'], keyboardEvent)) {
      return rootKeyForMacShiftAltKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    return false;
  }
  var ALL_MODIFIERS_EXCEPT_CMD = _modifiersArray.default.filter(function (m) {
    return m != 'cmd';
  });
  function onlyModifiers(names, obj) {
    var _iterator = _createForOfIteratorHelper(ALL_MODIFIERS_EXCEPT_CMD),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var modifier = _step.value;
        if (names.includes(modifier) && !obj["".concat(modifier, "Key")]) {
          return false;
        }
        if (!names.includes(modifier) && obj["".concat(modifier, "Key")]) {
          return false;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return true;
  }
  function rootKeyForShiftKey(key) {
    return _keyMaps.SHIFT_KEY_MAP[key] || key;
  }
  function rootKeyForMacAltKey(key) {
    return _keyMaps.MAC_ALT_KEY_MAP[key] || key;
  }
  function rootKeyForMacShiftAltKey(key) {
    return _keyMaps.MAC_SHIFT_ALT_KEY_MAP[key] || key;
  }
});
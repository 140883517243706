define("ember-mapbox-gl/components/mapbox-gl-marker", ["exports", "@ember/debug", "@ember/polyfills", "@ember/application", "@ember/component", "ember-mapbox-gl/templates/components/mapbox-gl-marker"], function (_exports, _debug, _polyfills, _application, _component, _mapboxGlMarker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A utility that brokers HTTP requests...
   *
   * @class MapboxGlMarker
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mapboxGlMarker.default,
    tagName: '',
    MapboxGl: null,
    map: null,
    initOptions: null,
    lngLat: null,
    init: function init() {
      var _getOwner$resolveRegi;
      this._super.apply(this, arguments);
      this.domContent = document.createElement('div');
      var lngLat = this.lngLat,
        initOptions = this.initOptions;
      (true && !(lngLat) && (0, _debug.assert)('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      var options = (0, _polyfills.assign)({}, ((_getOwner$resolveRegi = (0, _application.getOwner)(this).resolveRegistration('config:environment')['mapbox-gl']) !== null && _getOwner$resolveRegi !== void 0 ? _getOwner$resolveRegi : {}).marker, initOptions);
      this.marker = new this.MapboxGl.Marker(this.domContent, options).setLngLat(lngLat).addTo(this.map);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var lngLat = this.lngLat;
      (true && !(lngLat) && (0, _debug.assert)('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      this.marker.setLngLat(lngLat);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.marker.remove();
    }
  });
});
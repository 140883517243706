define("ember-cli-pubnub/utils/event-string", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.messageEventString = messageEventString;
  _exports.presenceEventString = presenceEventString;
  /**
   * Message event string. Use this to seed
   * the pubnub event handlers.
   *
   * @method messageEventString
   *
   * @param {String} channel
   *   The name of the channel.
   *
   * @return {String}
   *   Message event string.
   */
  function messageEventString(channel) {
    (true && !(channel) && (0, _debug.assert)('channel required for message event', channel));
    return "pn-message:".concat(channel);
  }

  /**
   * Presence event string. Use this to seed
   * the pubnub event handlers.
   *
   * @method presenceEventString
   *
   * @param {String} channel
   *   The name of the channel.
   *
   * @return {String}
   *   Presence event string.
   */
  function presenceEventString(channel) {
    (true && !(channel) && (0, _debug.assert)('channel required for presence event', channel));
    return "pn-presence:".concat(channel);
  }
});
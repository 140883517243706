define("ember-mapbox-gl/components/mapbox-gl-call", ["exports", "@ember/debug", "@ember/runloop", "@ember/component"], function (_exports, _debug, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _toArray(r) { return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   * A component used to call a specific Mapbox GL method.
   *
   * @class MapboxGLCallComponent
   */
  var MapboxGlCallComponent = _component.default.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,
    onResp: function onResp() {},
    params: null,
    _scheduledCall: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var obj = this.obj,
        func = this.func,
        args = this.args,
        params = this.params;
      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          var _params = _toArray(params);
          func = _params[0];
          args = _params.slice(1);
        }
      }
      (true && !(_typeof(obj) === 'object' && obj !== null) && (0, _debug.assert)('mapbox-gl-call obj is required', _typeof(obj) === 'object' && obj !== null));
      (true && !(typeof func === 'string') && (0, _debug.assert)('mapbox-gl-call func is required and must be a string', typeof func === 'string'));
      (true && !(typeof obj[func] === 'function') && (0, _debug.assert)("mapbox-gl-call ".concat(func, " must be a function on ").concat(obj), typeof obj[func] === 'function'));
      this._scheduledCall = (0, _runloop.scheduleOnce)('afterRender', this, this._call, obj, func, args);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      if (this._scheduledCall !== null) {
        (0, _runloop.cancel)(this._scheduledCall);
      }
    },
    _call: function _call(obj, func, args) {
      this._scheduledCall = null;
      this.onResp(obj[func].apply(obj, args));
    }
  });
  MapboxGlCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = _exports.default = MapboxGlCallComponent;
});
define("ember-cli-mirage/start-mirage", ["exports", "ember-cli-mirage/utils/read-modules", "ember-cli-mirage/server", "ember-inflector"], function (_exports, _readModules, _server, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = startMirage;
  /**
    Helper to start mirage. This should not be called directly. In rfc232/rfc268
    tests, use `setupMirage()` or the `autoboot` option in the addon config
    in the environment. In legacy tests that call `startMirage` directly, this
    should be called via the `startMirage` method exported from
    `<app>/initializers/ember-cli-mirage`.
  
    This is intended to be called with only the `owner` argument (which would be
    `this.owner` in an rfc232/rfc268 test, or the application instance if called
    from an instance initializer). However, to support the legacy initializer, it
    can instead accept a hash of the environment and config objects.
  
    @hide
  */
  function startMirage(owner) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      env = _ref.env,
      baseConfig = _ref.baseConfig,
      testConfig = _ref.testConfig,
      makeServer = _ref.makeServer;
    if (!env || !baseConfig) {
      if (!owner) {
        throw new Error('You must pass `owner` to startMirage()');
      }
      env = env || resolveRegistration(owner, 'config:environment');
      // These are set from `<app>/initializers/ember-cli-mirage`
      baseConfig = baseConfig || resolveRegistration(owner, 'mirage:base-config');
      testConfig = testConfig || resolveRegistration(owner, 'mirage:test-config');
      makeServer = makeServer || resolveRegistration(owner, 'mirage:make-server');
    }
    var environment = env.environment;
    var mirageEnvironment = env['ember-cli-mirage'] || {};
    var discoverEmberDataModels = mirageEnvironment.discoverEmberDataModels;
    if (discoverEmberDataModels === undefined) {
      discoverEmberDataModels = true;
    }
    var modules = (0, _readModules.default)(env.modulePrefix);
    var options = Object.assign(modules, {
      environment: environment,
      routes: baseConfig,
      testConfig: testConfig,
      discoverEmberDataModels: discoverEmberDataModels
    });
    options.trackRequests = mirageEnvironment.trackRequests;
    options.inflector = {
      singularize: _emberInflector.singularize,
      pluralize: _emberInflector.pluralize
    };
    var server;
    if (makeServer) {
      server = makeServer(options);
      if (typeof location !== 'undefined' && location.search.indexOf('mirageLogging') !== -1) {
        server.logging = true;
      }
    } else {
      server = new _server.default(options);
    }
    return server;
  }

  // Support Ember 1.13
  function resolveRegistration(owner) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    if (owner.resolveRegistration) {
      return owner.resolveRegistration.apply(owner, args);
    } else if (owner.__container__) {
      var _owner$__container__;
      return (_owner$__container__ = owner.__container__).lookupFactory.apply(_owner$__container__, args);
    } else {
      var _owner$container;
      return (_owner$container = owner.container).lookupFactory.apply(_owner$container, args);
    }
  }
});
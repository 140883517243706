define("ember-keyboard/utils/handle-key-event", ["exports", "ember-keyboard/utils/is-key"], function (_exports, _isKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleKeyEventWithPropagation = handleKeyEventWithPropagation;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function handleKeyEventWithPropagation(event, _ref) {
    var firstResponders = _ref.firstResponders,
      normalResponders = _ref.normalResponders;
    var isImmediatePropagationStopped = false;
    var isPropagationStopped = false;
    var ekEvent = {
      stopImmediatePropagation: function stopImmediatePropagation() {
        isImmediatePropagationStopped = true;
      },
      stopPropagation: function stopPropagation() {
        isPropagationStopped = true;
      }
    };
    var _iterator = _createForOfIteratorHelper(firstResponders),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var responder = _step.value;
        triggerResponderListener(responder, event, ekEvent);
        if (isImmediatePropagationStopped) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    if (isPropagationStopped) {
      return;
    }
    isImmediatePropagationStopped = false;
    var previousPriorityLevel = Number.POSITIVE_INFINITY;
    var _iterator2 = _createForOfIteratorHelper(normalResponders),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _responder = _step2.value;
        var currentPriorityLevel = Number(_responder.keyboardPriority);
        if (isImmediatePropagationStopped && currentPriorityLevel === previousPriorityLevel) {
          continue;
        }
        if (currentPriorityLevel < previousPriorityLevel) {
          if (isPropagationStopped) {
            return;
          }
          isImmediatePropagationStopped = false;
          previousPriorityLevel = currentPriorityLevel;
        }
        triggerResponderListener(_responder, event, ekEvent);
      }
      /* eslint-enable no-unused-vars */
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
  function triggerResponderListener(responder, event) {
    var ekEvent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    if (responder.handleKeyboardEvent) {
      if (responder.canHandleKeyboardEvent && !responder.canHandleKeyboardEvent(event)) {
        return;
      }
      responder.handleKeyboardEvent(event, ekEvent);
      return;
    }
    if (responder.keyboardHandlers) {
      Object.keys(responder.keyboardHandlers).forEach(function (responderListenerName) {
        if ((0, _isKey.default)(responderListenerName, event)) {
          if (ekEvent) {
            responder.keyboardHandlers[responderListenerName](event, ekEvent);
          } else {
            responder.keyboardHandlers[responderListenerName](event);
          }
        }
      });
      return;
    }
    throw new Error('A responder registered with the ember-keyboard service must implement either `keyboardHandlers` (property returning a dictionary of listenerNames to handler functions), or `handleKeyboardEvent(event)`)');
  }
});
define("ember-table/components/ember-table/component", ["exports", "@ember/component", "@ember/object", "@ember/string", "ember-table/-private/sticky/table-sticky-polyfill", "ember-table/components/ember-table/template"], function (_exports, _component, _object, _string, _tableStickyPolyfill, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    The primary Ember Table component. This component represents the root of the
    table, and manages high level state of all of its subcomponents. It does not
    have any arguments or actions itself - instead, all of those concerns are
    delegated to its children, who communicate to each other via the API.
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{this.columns}} />
      <t.body @rows={{this.rows}} />
      <t.foot @rows={{this.footerRows}} />
    </EmberTable>
    ```
  
    @yield {object} table - the API object yielded by the table
    @yield {Component} table.head - The table header component
    @yield {Component} table.body - The table body component
    @yield {Component} table.foot - The table footer component
    @class {{ember-table}}
    @public
  */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNames: ['ember-table'],
    attributeBindings: ['dataTestEmberTable:data-test-ember-table'],
    dataTestEmberTable: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var thead = this.element.querySelector('thead');
      var tfoot = this.element.querySelector('tfoot');
      if (thead) {
        (0, _tableStickyPolyfill.setupTableStickyPolyfill)(thead);
      }
      if (tfoot) {
        (0, _tableStickyPolyfill.setupTableStickyPolyfill)(tfoot);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var thead = this.element.querySelector('thead');
      var tfoot = this.element.querySelector('tfoot');
      if (thead) {
        (0, _tableStickyPolyfill.teardownTableStickyPolyfill)(this.element.querySelector('thead'));
      }
      if (tfoot) {
        (0, _tableStickyPolyfill.teardownTableStickyPolyfill)(this.element.querySelector('tfoot'));
      }
      this._super.apply(this, arguments);
    },
    tableStyle: (0, _object.computed)('tableWidth', function () {
      return (0, _string.htmlSafe)("width: ".concat(this.get('tableWidth'), "px;"));
    }),
    api: (0, _object.computed)(function () {
      return {
        columns: null,
        registerColumnTree: this.registerColumnTree.bind(this),
        tableId: "".concat(this.elementId, "-overflow")
      };
    }),
    registerColumnTree: function registerColumnTree(columnTree) {
      this.set('api.columnTree', columnTree);
    }
  });
});
define("@ember/legacy-built-in-components/components/_has-dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  // check if window exists and actually is the global
  var _default = _exports.default = (typeof self === "undefined" ? "undefined" : _typeof(self)) === 'object' && self !== null && self.Object === Object && typeof Window !== 'undefined' && self.constructor === Window && (typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object' && document !== null && self.document === document && (typeof location === "undefined" ? "undefined" : _typeof(location)) === 'object' && location !== null && self.location === location && (typeof history === "undefined" ? "undefined" : _typeof(history)) === 'object' && history !== null && self.history === history && (typeof navigator === "undefined" ? "undefined" : _typeof(navigator)) === 'object' && navigator !== null && self.navigator === navigator && typeof navigator.userAgent === 'string';
});
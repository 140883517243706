function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("liquid-fire/transitions/explode", ["exports", "@ember/array", "@ember/object/internals", "liquid-fire"], function (_exports, _array, _internals, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = explode;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  // Explode is not, by itself, an animation. It exists to pull apart
  // other elements so that each of the pieces can be targeted by
  // animations.

  var deduplicateChildElementIds = function deduplicateChildElementIds(parentElem) {
    if (!parentElem) {
      return;
    }
    var parentEl = parentElem;
    if (parentEl.id) {
      parentEl.setAttribute('id', "".concat((0, _internals.guidFor)(parentEl), "-").concat(parentEl.id));
    }
    var childrenWithUniqueIds = parentEl.querySelectorAll('[id]');
    if (childrenWithUniqueIds.length) {
      var _iterator = _createForOfIteratorHelper(childrenWithUniqueIds),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var el = _step.value;
          el.setAttribute('id', "".concat((0, _internals.guidFor)(el), "-").concat(el.id));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  };
  function explode() {
    var _this = this;
    var seenElements = {};
    var sawBackgroundPiece = false;
    for (var _len = arguments.length, pieces = new Array(_len), _key = 0; _key < _len; _key++) {
      pieces[_key] = arguments[_key];
    }
    var promises = pieces.map(function (piece) {
      if (piece.matchBy) {
        return matchAndExplode(_this, piece, seenElements);
      } else if (piece.pick || piece.pickOld || piece.pickNew) {
        return explodePiece(_this, piece, seenElements);
      } else {
        sawBackgroundPiece = true;
        return runAnimation(_this, piece);
      }
    });
    if (!sawBackgroundPiece) {
      if (this.newElement) {
        this.newElement.style.visibility = '';
      }
      if (this.oldElement) {
        this.oldElement.style.visibility = 'hidden';
      }
    }
    return _liquidFire.Promise.all(promises);
  }
  function explodePiece(context, piece, seen) {
    var childContext = _objectSpread({}, context);
    var selectors = [piece.pickOld || piece.pick, piece.pickNew || piece.pick];
    var cleanupOld, cleanupNew;
    if (selectors[0] || selectors[1]) {
      cleanupOld = _explodePart(context, 'oldElement', childContext, selectors[0], seen);
      cleanupNew = _explodePart(context, 'newElement', childContext, selectors[1], seen);
      if (!cleanupOld && !cleanupNew) {
        return _liquidFire.Promise.resolve();
      }
    }
    return runAnimation(childContext, piece).finally(function () {
      if (cleanupOld) {
        cleanupOld();
      }
      if (cleanupNew) {
        cleanupNew();
      }
    });
  }
  function _explodePart(context, field, childContext, selector, seen) {
    var child, childOffset, width, height, newChild;
    var elt = context[field];
    childContext[field] = null;
    if (elt && selector) {
      child = _toConsumableArray(elt.querySelectorAll(selector)).filter(function (elm) {
        var guid = (0, _internals.guidFor)(elm);
        if (!seen[guid]) {
          seen[guid] = true;
          return true;
        }
      });
      if (child.length > 0) {
        child = child[0];
        childOffset = getOffset(child);
        width = child.offsetWidth;
        height = child.offsetHeight;
        newChild = child.cloneNode(true);
        deduplicateChildElementIds(newChild);

        // Hide the original element
        child.style.visibility = 'hidden';

        // If the original element's parent was hidden, hide our clone
        // too.
        if (elt.style.visibility === 'hidden') {
          newChild.style.visibility = 'hidden';
        }
        elt.parentElement.append(newChild);
        newChild.style.width = "".concat(width, "px");
        newChild.style.height = "".concat(height, "px");
        var newParentOffset = getOffset(newChild.offsetParent);
        css(newChild, {
          position: 'absolute',
          top: childOffset.top - newParentOffset.top + 'px',
          left: childOffset.left - newParentOffset.left + 'px',
          margin: 0
        });

        // Pass the clone to the next animation
        childContext[field] = newChild;
        return function cleanup() {
          newChild.remove();
          child.style.visibility = '';
        };
      }
    }
  }
  function getOffset(ele) {
    var _ele$getBoundingClien;
    var rect = (_ele$getBoundingClien = ele === null || ele === void 0 ? void 0 : ele.getBoundingClientRect()) !== null && _ele$getBoundingClien !== void 0 ? _ele$getBoundingClien : {
      top: 0,
      left: 0
    };
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX
    };
  }
  function css(element, styles) {
    for (var property in styles) element.style[property] = styles[property];
  }
  function animationFor(context, piece) {
    var name, args, func;
    if (!piece.use) {
      throw new Error("every argument to the 'explode' animation must include a followup animation to 'use'");
    }
    if ((0, _array.isArray)(piece.use)) {
      name = piece.use[0];
      args = piece.use.slice(1);
    } else {
      name = piece.use;
      args = [];
    }
    if (typeof name === 'function') {
      func = name;
    } else {
      func = context.lookup(name);
    }
    return function () {
      return _liquidFire.Promise.resolve(func.apply(this, args));
    };
  }
  function runAnimation(context, piece) {
    return new _liquidFire.Promise(function (resolve, reject) {
      animationFor(context, piece).apply(context).then(resolve, reject);
    });
  }
  function matchAndExplode(context, piece, seen) {
    if (!context.oldElement || !context.newElement) {
      return _liquidFire.Promise.resolve();
    }

    // reduce the matchBy scope
    if (piece.pick) {
      context.oldElement = context.oldElement.querySelector(piece.pick);
      context.newElement = context.newElement.querySelector(piece.pick);
    }
    if (piece.pickOld) {
      context.oldElement = context.oldElement.querySelector(piece.pickOld);
    }
    if (piece.pickNew) {
      context.newElement = context.newElement.querySelector(piece.pickNew);
    }

    // use the fastest selector available
    var selector;
    if (piece.matchBy === 'id') {
      selector = function selector(attrValue) {
        return "#".concat(attrValue);
      };
    } else if (piece.matchBy === 'class') {
      selector = function selector(attrValue) {
        return ".".concat(attrValue);
      };
    } else {
      selector = function selector(attrValue) {
        var escapedAttrValue = attrValue.replace(/'/g, "\\'");
        return "[".concat(piece.matchBy, "='").concat(escapedAttrValue, "']");
      };
    }
    var hits = (0, _array.A)(_toConsumableArray(context.oldElement.querySelectorAll("[".concat(piece.matchBy, "]"))));
    return _liquidFire.Promise.all(hits.map(function (elt) {
      var attrValue = elt.getAttribute(piece.matchBy);

      // if there is no match for a particular item just skip it
      if (attrValue === '' || context.newElement.querySelectorAll(selector(attrValue)).length === 0) {
        return _liquidFire.Promise.resolve();
      }
      return explodePiece(context, {
        pick: selector(attrValue),
        use: piece.use
      }, seen);
    }));
  }
});